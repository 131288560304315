.hamburger {
    font-size: 2rem;
    cursor: pointer;
    display: none;
}

@media (max-width: 768px) {
    .hamburger {
        display: block;
    }
}

.mobile-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 10;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    cursor: pointer;
}

.nav-link {
    color: #333;
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
    padding: 20px;
    display: block;
}

/* General reset for smooth integration */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    background-color: #fff;
    color: #333;
}

/* App container */
.app {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    z-index: 1; /* Ensures your content is above particles */
}

/* Particle Background (full screen behind content) */
#tsparticles {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Places particles behind content */
}

/* Logo styles */
.logo {
    font-size: 1.5rem;
    font-weight: bold;
}

/* Navigation styles */
.nav {
    display: flex;
    gap: 1rem;
}

.nav a {
    text-decoration: none;
    color: #333;
    font-size: 1.1rem;
    font-weight: bold;
}

.nav a:hover {
    color: #666;
}

/* Footer styles */
footer {
    padding: 1rem;
    background-color: rgba(248, 248, 248, 0.9);
    text-align: center;
    margin-top: 2rem;
    border-top: 1px solid #ddd;
}

.footer-text {
    font-size: 0.9rem;
    color: #666;
}
